import {
  GIRL_NAME,
  GIRL_FATHER_NAME,
  GIRL_MOTHER_NAME,
  GIRL_NAME_SHORT,
  // boy section
  BOY_FATHER_NAME,
  BOY_NAME,
  BOY_MOTHER_NAME,
  BOY_NAME_SHORT,
  // another
  BROADCAST_WEDDING_DAY,
  BROADCAST_WEDDING_LOCATION,
  BROADCAST_WEDDING_LOCATION_AKAD,
  WEDDING_RESEPSI_TIME,
  WEDDING_AKAD_TIME,
  IS_BOY_FIRST,
} from '@/constants';
import { customText } from './customText';
//import { InvertText } from './InvertText';
import { MESSAGE } from './templateText';

const FINAL_BROADCAST = {
  ...MESSAGE,
  ...customText,
};

function getBroadcastText({ link, guestName, id, time, shift }) {
  time = WEDDING_AKAD_TIME
  const lang = id.includes('EN_') ? 'en' : 'id';
  const location = shift == 1 ? BROADCAST_WEDDING_LOCATION_AKAD : BROADCAST_WEDDING_LOCATION
const Holmat = {
id:`*Holy Matrimony*
Hari: ${BROADCAST_WEDDING_DAY['id']}
Waktu: ${WEDDING_AKAD_TIME}
Lokasi: ${BROADCAST_WEDDING_LOCATION_AKAD}`,
en: `*Holy Matrimony*
Date: ${BROADCAST_WEDDING_DAY['en']}
Time: ${WEDDING_AKAD_TIME}
Location: ${BROADCAST_WEDDING_LOCATION_AKAD}`
  } 
  // const Reception = {
  //   id:`
  //   *Wedding Reception*
  //   Hari: ${BROADCAST_WEDDING_DAY['id']}
  //   Waktu: ${WEDDING_RESEPSI_TIME}
  //   Lokasi: ${BROADCAST_WEDDING_LOCATION}
  //   `,
  //   en: `*Wedding Reception*
  //   Date: ${BROADCAST_WEDDING_DAY['en']}
  //   Time: ${WEDDING_RESEPSI_TIME}
  //   Location: ${BROADCAST_WEDDING_LOCATION}`
  // } 
const Both = {
id:`*Holy Matrimony*
Hari: ${BROADCAST_WEDDING_DAY['id']}
Waktu: ${WEDDING_AKAD_TIME}
Lokasi: ${BROADCAST_WEDDING_LOCATION_AKAD}

*Wedding Reception*
Hari: ${BROADCAST_WEDDING_DAY['id']}
Waktu: ${WEDDING_RESEPSI_TIME}
Lokasi: ${BROADCAST_WEDDING_LOCATION}`,
en: `*Holy Matrimony*
Date: ${BROADCAST_WEDDING_DAY['en']}
Time: ${WEDDING_AKAD_TIME}
Location: ${BROADCAST_WEDDING_LOCATION_AKAD}

*Wedding Reception*
Date: ${BROADCAST_WEDDING_DAY['en']}
Time: ${WEDDING_RESEPSI_TIME}
Location: ${BROADCAST_WEDDING_LOCATION}`
} 

  let event = ''
  if (shift == 1) event = Holmat[lang]
  else event = Both[lang]
  

  const result = FINAL_BROADCAST[id]
    .replace('{{GUEST_NAME}}', guestName)
    .replace('{{LINK}}', link)
    .replace('{{BOY_NAME}}', IS_BOY_FIRST ? BOY_NAME : GIRL_NAME)
    .replace('{{GIRL_NAME}}', IS_BOY_FIRST ? GIRL_NAME : BOY_NAME)
    .replace(
      '{{GIRL_FATHER_NAME_ID}}',
      IS_BOY_FIRST
        ? '_Putri dari Bapak ' + GIRL_FATHER_NAME
        : '_Putra dari Bapak ' + BOY_FATHER_NAME,
    )
    .replace(
      '{{GIRL_FATHER_NAME_EN}}',
      IS_BOY_FIRST
        ? '_The daughter of Mr. ' + GIRL_FATHER_NAME
        : '_The son of Mr. ' + BOY_FATHER_NAME,
    )
    .replace('{{GIRL_MOTHER_NAME}}', IS_BOY_FIRST ? GIRL_MOTHER_NAME : BOY_MOTHER_NAME)
    .replace(
      '{{BOY_FATHER_NAME_ID}}',
      IS_BOY_FIRST
        ? '_Putra dari Bapak ' + BOY_FATHER_NAME
        : '_Putri dari Bapak ' + GIRL_FATHER_NAME,
    )
    .replace(
      '{{BOY_FATHER_NAME_EN}}',
      IS_BOY_FIRST
        ? '_The son of Mr. ' + BOY_FATHER_NAME
        : '_The daughter of Mr. ' + GIRL_FATHER_NAME,
    )
    .replace('{{BOY_MOTHER_NAME}}', IS_BOY_FIRST ? BOY_MOTHER_NAME : GIRL_MOTHER_NAME)
    .replace('{{GIRL_NAME_SHORT}}', IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT)
    .replace('{{BOY_NAME_SHORT}}', IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT)
    .replace('{{BROADCAST_WEDDING_LOCATION}}', location)
    .replace('{{TIME}}', time)
    .replace('{{BROADCAST_WEDDING_DAY}}', BROADCAST_WEDDING_DAY[lang])
    .replace('{{EVENT}}', event);

  return result;
}

export default getBroadcastText;
